import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translation from './locales/translation.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: (navigator.language || navigator.userLanguage).indexOf('en') === 0 ? 'en' : 'fr',
  fallbackLocale: 'transalation',
  messages: translation
})

export default i18n
